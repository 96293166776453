import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Margin from "../components/margin"
import Leading from "../components/leading"
import SectionLine from "../components/section-line"


const DisclaimerPage: React.FC = () => (
  <Layout>
    <Seo title="Disclaimer" />

    <div className="container">
      <Leading main="免責事項" sub="Disclaimer"/>
      <div className="container-text">
        <div className="box box-plain">
          <p>
            当サイト上の情報は投資アドバイスを目的に制作されたものではありません。<br />
            <br />
            当サイト上の情報には誤情報や古くなった情報が含まれる可能性があり、必ずしも正確性を保証するものではありません。<br />
            <br />
            当サイト・ステークプールのご利用によって生じたあらゆる損失（機会損失や逸失利益を含む）について、一切の責任を負いかねますのでご了承ください。CardanoステーキングはOuroborosプロトコルの性質を理解し自己責任で行う必要があります。<br />
          </p>
          <p>
            Information on this site is not intended as financial or investment advice.<br />
            <br />
            This site may contain inaccuracies or outdated information and we do not warrant the accuracy.<br />
            <br />
            We are not liable for any loss caused by using this site or stake pool. Cardano staking must be done at your own risk and with understanding of how the Ouroboros protocol works.<br />
          </p>
        </div>
      </div>
    </div>

    <Margin height="0.5rem"/>
    <SectionLine />

    <div className="container">
      <p>
        <Link to="/" style={{ display: "inline" }}>
          <strong>トップページ - Top Page</strong>
        </Link>
      </p>
    </div>

  </Layout>
)

export default DisclaimerPage
