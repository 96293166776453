import * as React from "react"


interface Props {
  main: string,
  sub: string
}

export const Leading: React.FC<Props> = ( { main, sub } ) => {
  return (
    <div className="leading">
      <h3 style={{ marginBottom: "0.1rem" }}>{ main }</h3>
      <h4 style={{ marginTop: "0", marginBottom: "1rem" }}>{ sub }</h4>
    </div>
  );
};

export default Leading
