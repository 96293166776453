import * as React from "react"


export const SectionLine: React.FC = () => {
  return (
    <div className="section-line"></div>
  );
};

export default SectionLine
