import * as React from "react"


interface Props {
  height: any,
}

export const Margin: React.FC<Props> = ( { height }) => {
  return (
    <div style={{ marginBottom: height }}></div>
  );
};

export default Margin
